
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VNode } from 'vue';
import GskPopover from '@/components/gsk-components/popover/GskPopover.vue';
import NewlineText from '@/components/NewlineText.vue';
import TablePagination from '@/components/table/TablePagination.vue';
import { BotColumn } from '@/types/logdata.types';
import { ScheduledReport } from '@/types/om27.types';
import { randId } from '@/utils/components';
import { padLeadingZero } from '@/utils/number';
import { openSuccessSnackbar } from '@/utils/components';
import {
  calculateTimeForCurrentOffset,
  getCurrentTzOffsetString,
} from '@/utils/datetime';

const GPopover = GskPopover as any;
const NT = NewlineText as any;

@Component({
  components: {
    TablePagination,
    GskPopover,
  }
})
export default class Om27ScheduledReportsGrid extends Vue {
  @Prop({ required: true, type: Array }) reports!: ScheduledReport[];
  @Prop({ required: true, type: String }) timezone!: string;
  @Prop({ type: Boolean }) borderAround!: boolean;
  @Prop({ type: Boolean }) dense!: boolean;

  page = 1;
  perPage = 25;
  
  get dayKeys(): string[] {
    return [
      'sun', 'mon', 'tue', 'wed',
      'thu', 'fri', 'sat',
    ];
  }

  get optionsKeysAsTitles(): string[] {
    return [
      'Parameterized',
      'Disabled',
    ];
  }

  get columns(): BotColumn<ScheduledReport>[] {
    return [{
      field: 'reportName',
      key: 'reportName',
      align: 'left',
      width: 200,
      title: 'Report Name',
      renderBodyCell: ({ row }, h) => {
        return <span class="cell cell--report-name">{ row.reportName }</span>;
      },
    }, {
      field: 'sqlQuery',
      key: 'sqlQuery',
      align: 'left',
      width: 180,
      title: 'SQL Query',
      renderBodyCell: ({ row }, h) => {
        return (
          <span class="cell cell--sql-query">
            {this.shortenText(row.sqlQuery)}
          </span>
        );
      },
    }, {
      field: 'toAddressees',
      key: 'toAddressees',
      align: 'left',
      width: 200,
      title: 'Addressees',
      renderBodyCell: ({ row }, h) => {
        const toEmails = row.toAddressees ? row.toAddressees.split(',') : [];
        const ccEmails = row.ccAddressees ? row.ccAddressees.split(',') : [];
        return (
          <ul class="cell cell--emails-list">
            <li><strong>To:</strong></li>
            <ul>{
              toEmails.length
                ? toEmails.map(email => <li>{ email }</li>)
                : <span>—</span>
            }</ul>
            <li></li>
            <li><strong>CC:</strong></li>
            <ul>{
              ccEmails.length
                ? ccEmails.map(email => <li>{ email }</li>)
                : <span>—</span>
            }</ul>
            
          </ul>
        );
      },
    }, {
      field: 'emailTemplate',
      key: 'emailTemplate',
      align: 'left',
      width: 150,
      title: 'Email Template',
      renderBodyCell: ({ row }, h) => {
        const id = randId();
        if (!row.subject && !row.emailTemplate) {
          return <span>—</span>;
        }
        return (
          <div>
            <span>{row.subject || ''}</span><br/>
            <span id={id}>
              { row.emailTemplate ? this.shortenText(row.emailTemplate) : '—' }
            </span>
            <GPopover anchorId={id} hover pinnable copy>
              <NT>
                  <strong style="font-size: 12px">Subject:</strong><br/>
                  <span>{row.subject || ''}</span><br/>
                  <strong style="font-size: 12px">Body:</strong><br/>
                  <span id={id}>{row.emailTemplate || ''}</span>
              </NT>
            </GPopover>
          </div>
        );
      },
    }, {
      field: 'sun',
      key: 'sun',
      align: 'center',
      width: 260,
      title: 'Run Days',
      renderBodyCell: ({ row }, h) => {
        const { dayKeys } = this;
        return (
          <div class="cell cell--rundays"> {
            dayKeys.map(
              (day) =>
              <span class={
                `runday ${ (row as any)[day] !== null ? 'runday--active': '' }`
              }>{ day }</span>
            )
          }</div>
        );
      },
    }, {
      field: 'firstRunHr',
      key: 'firstRunHr',
      align: 'center',
      width: 150,
      title: 'Run Times (HH:mm)',
      renderBodyCell: ({ row }, h) => {
        const firstHour = padLeadingZero(row.firstRunHr, 2);
        const firstMinute = padLeadingZero(row.firstRunMin, 2);
        const secondHour = typeof row.secondRunHr === 'number' ? padLeadingZero(row.secondRunHr, 2) : null;
        const secondMinute = typeof row.secondRunMin === 'number' ? padLeadingZero(row.secondRunMin, 2) : null;

        return (
          <div>
            <div>1st run: <strong>{ firstHour }:{ firstMinute }</strong></div>
            <div>
              2nd run: {
                secondHour !== null && secondMinute !== null
                  ? <strong>{ secondHour }:{ secondMinute }</strong>
                  : <span>—</span>
              }
            </div>
          </div>
        );
      },
    },
    {
      field: 'parameterized',
      key: 'parameterized',
      align: 'center',
      width: 120,
      title: 'Options',
      renderBodyCell: ({ row }, h) => {
        const { optionsKeysAsTitles } = this;
        return (
          <ul class="cell cell--options">{
            optionsKeysAsTitles.map(
              (key) => (
                <li>
                  <span>{key}:</span>
                  {
                    (row as any)[key.toLowerCase()]
                      ? <gsk-icon
                          class="icon icon--true"
                        >checkmark_filled</gsk-icon>
                      : <gsk-icon
                          class="icon icon--false"
                        >close_circle</gsk-icon>
                  }
                </li>)
            )
          }</ul>
        );
      },
    }, {
      field: 'actions',
      key: 'actions',
      align: 'center',
      width: 150,
      title: 'Actions',
      renderBodyCell: ({ row }, h) => {
        const idView = randId();
        const idEdit = randId();
        const idDelete = randId();
        return (
          <div class="cell--actions">
            <gsk-icon-button
              id={idView}
              primary
              icon="document_export"
              class="icon"
              onClick={() => this.$emit('view-report', {
                reportName: row.reportName,
                parameterized: row.parameterized,
              })}
            ></gsk-icon-button>
            <GPopover anchorId={idView} hover>View Report</GPopover>
            <gsk-icon-button
              id={idEdit}
              primary
              icon="pencil"
              class="icon"
              onClick={() => this.$emit('edit-report', {
                reportName: row.reportName,
              })}
            ></gsk-icon-button>
            <GPopover anchorId={idEdit} hover>Edit Report</GPopover>
            <gsk-icon-button
              id={idDelete}
              primary
              icon="trash"
              class="icon icon--red"
              onClick={() => this.$emit('delete-report', {
                reportName: row.reportName,
              })}
            ></gsk-icon-button>
            <GPopover anchorId={idDelete} hover>Delete Report</GPopover>
          </div>
        );
      },
    }];
  }

  get rows() {
    let start = (this.page - 1) * this.perPage;
    if (start > this.reports.length) {
      this.page = Math.ceil(this.reports.length / this.perPage);
      start = (this.page - 1) * this.perPage;
    }
    const end = start + this.perPage;
    return this.reports.slice(start, end);
  }

  copyTextToClipboard(text: string): void {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text);
      openSuccessSnackbar.call(this, 'Copied to clipboard');
    }
  }

  shortenText(text: string): VNode | VNode[] {
    const str = text.length > 80 ? text.substring(0, 100) : text;
    return (
      <div class="shortened-text">
        {str} { str.length < text.length ? '...' : '' }
        <gsk-icon-button
          mini
          primary
          icon="copy"
          class="icon"
          onClick={this.copyTextToClipboard.bind(this, text)}
        ></gsk-icon-button>
      </div>
    );
  }
}

